import Vue from 'vue';

const initialState = () => {
    return {
        loadingState: false,
        configuratorOptions: {},
        configuratorGroupOptionValues: {},
        configurationHash: '',
        configurationExtraImagesHash: '',
        configurationCode: '',
        configurationPrices: {},
        isPreset: false,

        configurationProductId: null,
        groupCurrentChildOptions: {},
        visibleSteps: {},
        configurationImages: {},
        configurationImagesExtra: {},
        configurationImagesExtraMapping: {
            '01': {
                label: 'Default view',
                icon: null,
                position: 1,
            },
            '02': {
                label: 'Side angle low view',
                icon: null,
                position: 2,
            },
            '03': {
                label: 'Side angle high view',
                icon: null,
                position: 3,
            },
            '04': {
                label: 'Front view',
                icon: null,
                position: 4,
            },
            '05': {
                label: 'Side view',
                icon: null,
                position: 5,
            },
            '06': {
                label: 'Side angle left view',
                icon: null,
                position: 6,
            },
            '07': {
                label: 'Side angle right view',
                icon: null,
                position: 7,
            },
        },
    }
};

const state = initialState();

const mutations = {
    SET_LOADING_STATE(state, payload): void {
        state.loadingState = payload;
    },
    SET_CONFIGURATOR_OPTIONS(state, payload): void {
        state.configuratorOptions = payload;
    },
    SET_CONFIGURATION_HASH(state, hash): void {
        state.configurationHash = hash;
    },
    SET_CONFIGURATION_PRODUCT_ID(state, productId): void {
        state.configurationProductId = productId;
    },
    SET_ACTIVE_CHILD_OPTION(state, payload): void {
        Vue.set(state.groupCurrentChildOptions, payload.code, payload.options);
    },
    SET_CONFIGURATOR_GROUP_OPTION_VALUES(state, payload): void {
        Vue.set(state.configuratorGroupOptionValues, payload.option, payload.value);
    },
    SET_CONFIGURATION_IMAGES(state: { configurationImages: object; }, payload: object): void {
        state.configurationImages = payload;
    },
    SET_CONFIGURATION_EXTRA_IMAGES_HASH(state, hash): void {
        state.configurationExtraImagesHash = hash;
    },
    SET_CONFIGURATION_EXTRA_IMAGES(state: { configurationImagesExtra: object; }, payload: object): void {
        state.configurationImagesExtra = payload;
    },
    TOGGLE_STEP_VISIBILITY(state, payload): void {
        let value = true;

        if (payload.hasOwnProperty('value')) {
            value = payload.value;
        } else if (state.visibleSteps.hasOwnProperty(payload.code)) {
            value = ! state.visibleSteps[payload.code];
        }

        Vue.set(state.visibleSteps, payload.code, value);
    },
    SET_CONFIGURATION_CODE(state: { configurationCode: string; }, payload: string): void {
        Vue.set(state, 'configurationCode', payload);
    },
    SET_CONFIGURATION_PRICES(state: { configurationPrices: object; }, payload: object): void {
        Vue.set(state, 'configurationPrices', payload);
    },
    SET_IS_PRESET(state: { isPreset: boolean; }, payload: boolean): void {
        Vue.set(state, 'isPreset', payload);
    }
};

const actions = {
    setConfigurationProductId({commit}, payload): void {
        commit('SET_CONFIGURATION_PRODUCT_ID', payload);
    },
    toggleStepVisibility({commit}, payload): void {
        commit('TOGGLE_STEP_VISIBILITY', payload);
    },
    setConfiguratorGroupOptionValue({state, commit, dispatch}, payload): void {
        commit('SET_CONFIGURATOR_GROUP_OPTION_VALUES', payload);

        if ( ! payload.value) {
            return;
        }

        const parent = state.configuratorOptions.steps.find(
            (step) => step.elements.some((element) => element.code === payload.option)
        )?.elements.find((element) => element.code === payload.option);

        if ( ! parent) {
            return;
        }

        const childOptions = parent.options.find((option) => option.code === payload.value);

        if (childOptions.hasOwnProperty('sub_options')) {
            commit('SET_ACTIVE_CHILD_OPTION', {code: parent.group.code, options: childOptions.sub_options});
        }

        dispatch('goToNextOption', payload.option);
    },
    findNextOption({}, payload): object {
        let returnNext = false;
        let nextOption = {};
        this.getters['CatalogProductConfigurator/getConfigurationSteps'].some((step) => {
            if (returnNext) {
                nextOption = {type: 'step', code: step.code};
                return true; // stop the loop
            }

            return step.elements.some((element) => {
                if (element.hidden) {
                    return false;
                }

                if (returnNext) {
                    nextOption = {type: 'element', code: element.code};
                    return true; // stop the loop
                }

                if (element.code === payload || (element.hasOwnProperty('group') && element.group['code'] === payload)) {
                    if (element['is_group'] && element.code === payload) {
                        nextOption = {
                            type: 'element',
                            code: element.group['code']
                        }
                        return true;
                    }
                    returnNext = true;
                }
            })
        })

        return nextOption;
    },
    async getConfiguratorOptions({commit, dispatch}): Promise<void> {
        commit('SET_LOADING_STATE', true);

        try {
            const postBody = {
                locale: window.Locale,
            };
            const {data} = await Vue.prototype.$solarClient.post(`${Vue.prototype.$dataImportApiBaseUrl}/dataimport/api/v1/configuration`, postBody);

            commit('SET_CONFIGURATOR_OPTIONS', data);
            commit('SET_CONFIGURATION_HASH', data.hash);
            commit('SET_CONFIGURATION_IMAGES', {
                image: data.image_hash,
                large_image: data.high_image_hash,
            });
            commit('SET_CONFIGURATION_EXTRA_IMAGES_HASH', data.external_hash);
            commit('SET_CONFIGURATION_PRICES', data.price);
        } catch (e) {
            console.error(e);
        } finally {
            commit('SET_LOADING_STATE', false);
            await dispatch('fetchExtraImages');
        }
    },
    async dispatchConfigurationOption({state, commit, getters, dispatch}, payload: object): Promise<void> {
        commit('SET_LOADING_STATE', true);

        try {
            const postBody = {
                selected: payload,
                hash: getters.getConfigurationHash,
                isPreset: state.isPreset,
                locale: window.Locale,
            };

            const {data} = await Vue.prototype.$solarClient.post(`${Vue.prototype.$dataImportApiBaseUrl}/dataimport/api/v1/configuration/selected`, postBody);

            commit('SET_CONFIGURATOR_OPTIONS', data);
            commit('SET_CONFIGURATION_HASH', data.hash);
            commit('SET_CONFIGURATION_IMAGES', {
                image: data.image_hash,
                large_image: data.high_image_hash,
            });
            commit('SET_CONFIGURATION_EXTRA_IMAGES_HASH', data.external_hash);
            commit('SET_CONFIGURATION_PRICES', data.price);
        } catch (e) {
            console.error(e);
        } finally {
            commit('SET_LOADING_STATE', false);
            await dispatch('fetchExtraImages');
        }

        if ( ! state.isPreset) {
            const productTitle = document.querySelector('h1');
            productTitle.innerHTML = productTitle.getAttribute('data-configurator-title');
        }

        if (state.isPreset) {
            commit('SET_IS_PRESET', false);
        }

        dispatch('goToNextOption', payload['option']);
    },
    async goToNextOption({dispatch}, payload): Promise<void> {
        const next = await dispatch('findNextOption', payload);

        if (next.hasOwnProperty('code')) {
            if (next.type === 'step') {
                dispatch('toggleStepVisibility', {code: next.code, value: true});
            }

            // document.getElementById(`${next.type}-${next.code}`)?.scrollIntoView({behavior: 'smooth'});
        }
    },
    async fetchExtraImages({state, commit}): Promise<void> {
        commit('SET_LOADING_STATE', true);

        try {
            const {data} = await Vue.prototype.$solarClient.post(`${Vue.prototype.$dataImportApiBaseUrl}/dataimport/api/v1/configuration/cameras`, {
                hash: state.configurationExtraImagesHash,
            });
            commit('SET_CONFIGURATION_EXTRA_IMAGES', data);

        } catch (e) {
            console.error(e);
            commit('SET_CONFIGURATION_EXTRA_IMAGES', {});
        } finally {
            commit('SET_LOADING_STATE', false);
        }
    },
    async fetchFilteredConfigurationSteps({state}): Promise<object> {
        let data = {};
        (state.configuratorOptions['steps'] || []).forEach((step: object) => {
            if (step.hasOwnProperty('elements') && step['elements'].length > 0) {
                step['elements'].forEach((element: object) => {
                    if (element['selected']) {
                        data[element['code']] = element['hidden'] ? null : element['selected'];
                    }

                    if (element.hasOwnProperty('elements') && element['elements'].length > 0) {
                        element['elements'].forEach((subElement: object) => {
                            data[subElement['code']] = subElement['hidden'] ? null : subElement['selected'];
                        });
                    }
                });
            }
        });
        return data;
    }
};

const getters = {
    getLoadingState: state => state.loadingState,
    getConfigurationHash: state => state.configurationHash,
    getConfigurationSteps: state => state.configuratorOptions.steps,
    getConfigurationProductId: state => state.configurationProductId,
    getConfiguratorGroupOptionValues: state => state.configuratorGroupOptionValues,
    getConfiguratorStepVisible: state => (step) => state.visibleSteps[step] || true,
    getCurrentChildOptions: (state: { groupCurrentChildOptions: object[] }) => state.groupCurrentChildOptions,
    getConfigurationImages: (state: { configuratorOptions: object, configurationImagesExtra: object }): Array<object> => {
        if (Object.keys(state.configurationImagesExtra).length > 0) {
            return Object.entries(state.configurationImagesExtra).map(([key, value]) => {
                return {
                    image: `${Vue.prototype.$dataImportImageBaseUrl}/images/direct/${value}?width=865&height=700`,
                    large_image: `${Vue.prototype.$dataImportImageBaseUrl}/images/direct/${value}?width=3200&height=1800`,
                };
            });
        }

        return [{
            'image': `${Vue.prototype.$dataImportImageBaseUrl}/images/direct/${state.configuratorOptions['external_hash']}?width=865&height=700`,
            'large_image': `${Vue.prototype.$dataImportImageBaseUrl}/images/direct/${state.configuratorOptions['external_hash']}?width=3200&height=1800`,
        }];
    },
    getConfigurationCode: (state: { configurationCode: string; }) => state.configurationCode,
    getConfigurationPrices: (state: { configurationPrices: object; }) => state.configurationPrices,
    getConfigurationSelectedOptions: (state: { configuratorOptions: { steps: any; }; }) => {
        let selectedObjects = {};
        (state.configuratorOptions['steps'] || []).forEach((step: object) => {
            if (step.hasOwnProperty('elements') && step['elements'].length > 0) {
                step['elements'].forEach((element: object) => {
                    if (element['selected']) {
                        selectedObjects[element['code']] = element['hidden'] ? null : element['selected'];
                    }

                    if (element.hasOwnProperty('elements') && element['elements'].length > 0) {
                        element['elements'].forEach((subElement: object) => {
                            selectedObjects[subElement['code']] = subElement['hidden'] ? null : subElement['selected'];
                        });
                    }
                });
            }
        });
        return selectedObjects;
    },
};

const CatalogProductConfigurator = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

export default CatalogProductConfigurator;
